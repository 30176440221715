.Buttons {
  width: 76% !important;
  display: flex;
  justify-content: space-between;
}
.Second_buttons {
  width: 30em !important;
  display: flex;
  justify-content: space-between;
}
.message {
  color: red;
}
.Button_class {
  background-color: #9c6fab !important;
  width: 10em;
  border-radius: 5px!important;
  font-weight: 600;
    font-size: .8571em;
    line-height: 1.35em;
    text-transform: uppercase;
    border: none;
    margin: 10px 1px;
    border-radius: 3px;
    padding: 11px ;
    text-align: center;
    cursor: pointer;
    background-color: #66615b;
    color: #fff;
    transition: all .15s linear;
}
.Send_button {
  width: 10em;
  background-color: #facb70 !important;
  color: black !important;
  border-radius: 5px !important;
}
.Textarea_class {
  border-radius: 10px !important;
  border: 2px solid #9c6fab !important;
  width: 75%;

  padding: 10px;
  box-sizing: border-box;
}
.Textarea_class1 {
  border-radius: 10px !important;
  width: 60% !important;
  height: 20px;
  font-weight: 900;
  padding: 5px;
  border: 2px solid #9c6fab !important;
}
.Send_Buttons {
  width: 76% !important;
  display: flex;
  justify-content: end;
}
.Files_upload {
  display: flex;
  align-items: center;
}
.Link_files {
  display: flex;
  align-items: center;
}

.fa-lg {
  font-size: 1.333333em;
  line-height: 2.75em;
  vertical-align: -15%;
  color: red;
}
